/* eslint-disable consistent-return */
/* eslint-disable global-require */
export const onClientEntry = () => {
  require('default-passive-events');
  require('typeface-montserrat');
  require('typeface-lato');

  import(`requestidlecallback`);

  if (!(`IntersectionObserver` in window)) {
    return import(`intersection-observer`);
  }

  // if (process.env.NODE_ENV !== 'development') {
  //   const hostName = window.location.hostname;
  //   const domain = hostName.split('.');
  //   if (domain[0] !== 'visa-applications') {
  //     const sub = domain[0];
  //     const dom = domain[1];
  //     const tld = domain[2];

  //     const path = window.location.pathname;

  //     const newUrl = `https://${dom}.${tld}/${sub}${path}`;

  //     console.log(`Redirecting to: `, newUrl);

  //     window.location.replace(newUrl);
  //   }
  // }
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This site has been updated. Reload to display the latest version?`,
  );
  if (answer === true) {
    window.location.reload();
  }
};
