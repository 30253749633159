module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://1e2f5c00a1a44815803e4a693a746800@o1066917.ingest.sentry.io/6368609","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://va.webpassion.app/graphql","presets":null,"schema":{"perPage":30,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":120000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"html":{"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"Comment":{"exclude":true},"ContentType":{"exclude":true},"PostFormat":{"exclude":true},"UserRole":{"exclude":true},"User":{"exclude":true},"Tag":{"exclude":true},"Customer":{"exclude":true},"Coupon":{"exclude":true},"ProductTag":{"exclude":true},"Order":{"exclude":true},"Refund":{"exclude":true},"ShippingMethod":{"exclude":true},"ShippingClass":{"exclude":true},"TaxRate":{"exclude":true},"PaymentGateway":{"exclude":true},"MediaItem":{"localFile":{"requestConcurrency":20,"excludeByMimeTypes":["application/zip"],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Visa Applications","short_name":"visaapplications","start_url":"/","background_color":"#003366","theme_color":"#003366","display":"standalone","icon":"src/images/visa-logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"431957f15b69edf620b245108e973a9b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BMNC4S5G49"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/sample","/landing-page-example","/landing-page-template","/api","/save-for-later","/apply-now/shop-category","/apply-now/shop-category/test-product","/?page_id=2754","/?page_id=1985","/?page_id=1988","/?page_id=1988","/?s={search_term_string}","/usa/?s={search_term_string}","/eu/poland-schengen/?gclid=CjwKCAjwxaXtBRBbEiwAPqPxcL9wNQ8NAv4tOvvAr02ZPp-kMK7hmcm6ikbHEbEGXbLM4JyCiLKpVRoC4VgQAvD_BwE","/eu/schengen-countries/?gclid=CjwKCAjwxaXtBRBbEiwAPqPxcL9wNQ8NAv4tOvvAr02ZPp-kMK7hmcm6ikbHEbEGXbLM4JyCiLKpVRoC4VgQAvD_BwE","/apply-now/?add-to-cart=2798"],"delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
